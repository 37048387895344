export default {
    index: '/',

    spoferan: {
        index: '/spoferan',
        virtual_events: '/spoferan/virtual-events',
        app: '/spoferan/app',
        events: '/spoferan/events',
        blog: '/spoferan/blog',
    },

    resultastic: {
        index: '/resultastic',
        pricing: '/resultastic/pricing',
        services: '/resultastic/services',

        features: {
            index: '/resultastic/features',
            bibkeeper: '/resultastic/features/bibkeeper',
            participant_management: '/resultastic/features/participant-management',
            team_management: '/resultastic/features/team-management',
        },
    },

    clubmanager: {
        index: '/clubmanager',
        pricing: '/clubmanager/pricing',

        features: {
            index: '/clubmanager/features'
        },
    },

    businessmanager: {
        index: '/businessmanager',
        pricing: '/businessmanager/pricing',

        features: {
            index: '/businessmanager/features',
            jobs: '/businessmanager/features/jobs',
            ads: '/businessmanager/features/ads'
        },

    },


}
