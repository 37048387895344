import type {RouterOptions} from "vue-router";

export default <RouterOptions>{
    scrollBehavior(to, from, savedPosition) {

        // If a hash is specified, we smoothly scroll to the hash
        if (to.hash) {
            const element = document.querySelector(to.hash);
            if (element) {
                setTimeout(() => {
                    const box = element.getBoundingClientRect();
                    const body = document.body;
                    const docEl = document.documentElement;
                    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
                    const clientTop = docEl.clientTop || body.clientTop || 0;
                    const top = box.top + scrollTop - clientTop;

                    window.scrollTo({
                        top: top - 56, // -56 due to header
                        behavior: 'smooth'
                    })
                }, 400); // Wait until the page with images are rendered as scrolling would not be calculated correctly otherwise
                return;
            }
        }

        // If the path to the last route change is the same, we do not scroll as only some query params changed (or the hash that is handled above)
        if (from && to && from.path === to.path) {
            return false;
        }

        // SavedPosition is only available for popstate navigations (back button) on which we want to restore the scroll position when the user clicked the link
        if (savedPosition) {
            return savedPosition
        }

        // Always scroll to top
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 50); // Wait until the new page is rendered as scrolling would not be calculated correctly otherwise
        return;
    },
};
