import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeInputNumber, LazyPrimeSelect, LazyPrimePopover } from '#components'
const lazyGlobalComponents = [
  ["PrimeInputNumber", LazyPrimeInputNumber],
["PrimeSelect", LazyPrimeSelect],
["PrimePopover", LazyPrimePopover],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
