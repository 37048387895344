import WebRoutes from '~/config/web-routes';
import {parseRoute} from "@spoferan/spoferan-ts-core";

export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            web: (name: string, params = {}, absolute: boolean = false) => {
                return parseRoute(WebRoutes, name, params, null, absolute ? nuxtApp.$config.public.appUrl : null)
            }
        }
    }
})
