<script setup lang="ts">
const {$image, $analytics} = useNuxtApp();
const route = useRoute();
const config = useRuntimeConfig();

function checkConsent() {
	const cmpData = __cmp('getCMPData');

	// No consent available yet
	if (!cmpData) {
		return;
	}

	const vendorConsents = cmpData.vendorConsents;
	if (vendorConsents['s26']) {
		$analytics.enable();
	}
}

onMounted(() => {
	if ( typeof __cmp === 'function' ) {
		checkConsent();
		__cmp("addEventListener",["consent",checkConsent,false],null);
	} else {
		let tries = 0;
		let interval = setInterval(() =>  {
			if (typeof __cmp === 'function') {
				clearInterval(interval);
				__cmp("addEventListener",["consent",checkConsent,false],null);
			} else {
				tries++;
				if (tries > 5) {
					clearInterval(interval);
				}
			}
		}, 3000);
	}
});

// This will be reactive even you change title/description above
useHead({
	htmlAttrs: {
		lang: 'de'
	},

	title: 'SPOFERAN - Mein Sportnetzwerk',

	meta: [
		{charset: 'utf-8'},
		{name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
		{
			name: 'description',
			content: 'Erfahre alles über die Idee der Sporteventcommunity SPOFERAN und über die Vorteile für Sportler:innen, Sportveranstalter, Sportvereine und Verbände.'
		},
		{name: 'author', content: 'SPOFERAN GmbH'},
		{name: 'theme-color', content: '#008200'},

		{
			'property': 'og:title',
			'content': `SPOFERAN - Mein Sportnetzwerk`,
		},
		{
			'property': 'og:type',
			'content': 'website',
		},
		{
			'property': 'og:site_name',
			'content': 'SPOFERAN - Mein Sportnetzwerk'
		},
		{
			'property': 'og:description',
			'content': 'Erfahre alles über die Idee der Sporteventcommunity SPOFERAN und über die Vorteile für Sportler:innen, Sportveranstalter, Sportvereine und Verbände.',
		},
		{
			'property': 'og:image',
			'content': $image.format('/images/spoferan-feature-image.jpg'),
		},
		{
			'property': 'og:url',
			'content': `${config.public.appUrl}${route.path}`,
		},

		// Fix for IE 11 rendering issues
		{
			'http-equiv': 'X-UA-Compatible',
			'content': 'IE=edge'
		},

		// Activate iOS PWA Splash Screen
		{name: 'apple-mobile-web-app-capable', content: 'yes'}
	],
	link: [
		{rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
		{rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
		{rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
		{rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
		{rel: 'mask-icon', type: 'image/png', color: '#008200', href: '/images/favicons/safari-pinned-tab.svg'},

		// Ensure query params do not destroy our SEO reputation
		{rel: 'canonical', href: `${config.public.appUrl}${route.path}`}
	],
	script: [
		{
			src: '/scripts/consent-manager.js',
		}
	]
})
</script>

<template>
	<NuxtLayout>
		<NuxtPage/>
	</NuxtLayout>
</template>
