import { default as adsxXbvpEeNUEMeta } from "/home/runner/work/about/about/src/pages/businessmanager/features/ads.vue?macro=true";
import { default as indexjDZJihouaoMeta } from "/home/runner/work/about/about/src/pages/businessmanager/features/index.vue?macro=true";
import { default as jobsTtGDzFhRlCMeta } from "/home/runner/work/about/about/src/pages/businessmanager/features/jobs.vue?macro=true";
import { default as indexu57azDj3L8Meta } from "/home/runner/work/about/about/src/pages/businessmanager/index.vue?macro=true";
import { default as pricingCcuKZOM2kLMeta } from "/home/runner/work/about/about/src/pages/businessmanager/pricing.vue?macro=true";
import { default as businessmanagerK5nSiC2rttMeta } from "/home/runner/work/about/about/src/pages/businessmanager.vue?macro=true";
import { default as indexMNQzlXmCD1Meta } from "/home/runner/work/about/about/src/pages/clubmanager/features/index.vue?macro=true";
import { default as indexAcM0pk2gbDMeta } from "/home/runner/work/about/about/src/pages/clubmanager/index.vue?macro=true";
import { default as pricinggkIDYaeoPQMeta } from "/home/runner/work/about/about/src/pages/clubmanager/pricing.vue?macro=true";
import { default as clubmanagera2r1Y6Amq1Meta } from "/home/runner/work/about/about/src/pages/clubmanager.vue?macro=true";
import { default as indexHU2I3sgG7zMeta } from "/home/runner/work/about/about/src/pages/index.vue?macro=true";
import { default as bibkeepermDH7rh3WhBMeta } from "/home/runner/work/about/about/src/pages/resultastic/features/bibkeeper.vue?macro=true";
import { default as indexq0yO6kJYN4Meta } from "/home/runner/work/about/about/src/pages/resultastic/features/index.vue?macro=true";
import { default as participant_45management1yZv26WnMUMeta } from "/home/runner/work/about/about/src/pages/resultastic/features/participant-management.vue?macro=true";
import { default as team_45managementqN59j8RKeEMeta } from "/home/runner/work/about/about/src/pages/resultastic/features/team-management.vue?macro=true";
import { default as featuresou7vrUJF1VMeta } from "/home/runner/work/about/about/src/pages/resultastic/features.vue?macro=true";
import { default as indexFjp5VbH9fVMeta } from "/home/runner/work/about/about/src/pages/resultastic/index.vue?macro=true";
import { default as pricingS0pN8mB3MBMeta } from "/home/runner/work/about/about/src/pages/resultastic/pricing.vue?macro=true";
import { default as services2b6QBrSvLiMeta } from "/home/runner/work/about/about/src/pages/resultastic/services.vue?macro=true";
import { default as resultasticDZslelgJ8ZMeta } from "/home/runner/work/about/about/src/pages/resultastic.vue?macro=true";
import { default as app93Mm4tnoQ5Meta } from "/home/runner/work/about/about/src/pages/spoferan/app.vue?macro=true";
import { default as blogX2bsgUYh28Meta } from "/home/runner/work/about/about/src/pages/spoferan/blog.vue?macro=true";
import { default as eventsIeGjWvLPBeMeta } from "/home/runner/work/about/about/src/pages/spoferan/events.vue?macro=true";
import { default as indexoB6vWaKSMqMeta } from "/home/runner/work/about/about/src/pages/spoferan/index.vue?macro=true";
import { default as virtual_45eventsqwVEbzhDhhMeta } from "/home/runner/work/about/about/src/pages/spoferan/virtual-events.vue?macro=true";
export default [
  {
    name: businessmanagerK5nSiC2rttMeta?.name,
    path: "/businessmanager",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager.vue").then(m => m.default || m),
    children: [
  {
    name: "businessmanager-features-ads___de",
    path: "features/ads",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager/features/ads.vue").then(m => m.default || m)
  },
  {
    name: "businessmanager-features___de",
    path: "features",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager/features/index.vue").then(m => m.default || m)
  },
  {
    name: "businessmanager-features-jobs___de",
    path: "features/jobs",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager/features/jobs.vue").then(m => m.default || m)
  },
  {
    name: "businessmanager___de",
    path: "",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager/index.vue").then(m => m.default || m)
  },
  {
    name: "businessmanager-pricing___de",
    path: "pricing",
    component: () => import("/home/runner/work/about/about/src/pages/businessmanager/pricing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: clubmanagera2r1Y6Amq1Meta?.name,
    path: "/clubmanager",
    component: () => import("/home/runner/work/about/about/src/pages/clubmanager.vue").then(m => m.default || m),
    children: [
  {
    name: "clubmanager-features___de",
    path: "features",
    component: () => import("/home/runner/work/about/about/src/pages/clubmanager/features/index.vue").then(m => m.default || m)
  },
  {
    name: "clubmanager___de",
    path: "",
    component: () => import("/home/runner/work/about/about/src/pages/clubmanager/index.vue").then(m => m.default || m)
  },
  {
    name: "clubmanager-pricing___de",
    path: "pricing",
    component: () => import("/home/runner/work/about/about/src/pages/clubmanager/pricing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/runner/work/about/about/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: resultasticDZslelgJ8ZMeta?.name,
    path: "/resultastic",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic.vue").then(m => m.default || m),
    children: [
  {
    name: featuresou7vrUJF1VMeta?.name,
    path: "features",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/features.vue").then(m => m.default || m),
    children: [
  {
    name: "resultastic-features-bibkeeper___de",
    path: "bibkeeper",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/features/bibkeeper.vue").then(m => m.default || m)
  },
  {
    name: "resultastic-features___de",
    path: "",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/features/index.vue").then(m => m.default || m)
  },
  {
    name: "resultastic-features-participant-management___de",
    path: "participant-management",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/features/participant-management.vue").then(m => m.default || m)
  },
  {
    name: "resultastic-features-team-management___de",
    path: "team-management",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/features/team-management.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "resultastic___de",
    path: "",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/index.vue").then(m => m.default || m)
  },
  {
    name: "resultastic-pricing___de",
    path: "pricing",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/pricing.vue").then(m => m.default || m)
  },
  {
    name: "resultastic-services___de",
    path: "services",
    component: () => import("/home/runner/work/about/about/src/pages/resultastic/services.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "spoferan-app___de",
    path: "/spoferan/app",
    component: () => import("/home/runner/work/about/about/src/pages/spoferan/app.vue").then(m => m.default || m)
  },
  {
    name: "spoferan-blog___de",
    path: "/spoferan/blog",
    component: () => import("/home/runner/work/about/about/src/pages/spoferan/blog.vue").then(m => m.default || m)
  },
  {
    name: "spoferan-events___de",
    path: "/spoferan/events",
    component: () => import("/home/runner/work/about/about/src/pages/spoferan/events.vue").then(m => m.default || m)
  },
  {
    name: "spoferan___de",
    path: "/spoferan",
    component: () => import("/home/runner/work/about/about/src/pages/spoferan/index.vue").then(m => m.default || m)
  },
  {
    name: "spoferan-virtual-events___de",
    path: "/spoferan/virtual-events",
    component: () => import("/home/runner/work/about/about/src/pages/spoferan/virtual-events.vue").then(m => m.default || m)
  }
]