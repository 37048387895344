<template>
	<NuxtLayout>
		<container>
			<div class="min-h-[60vh] py-24 flex flex-col items-center justify-center">
				<div class="rounded-full bg-warning/20 p-4 mb-5">
					<icon :icon="icons.warning" x-large class="text-warning justify-center"/>
				</div>
				<h1 class="text-center font-bold mb-3 text-gray-800 text-2xl md:text-3xl dark:text-white">{{ errorTitle }}</h1>
				<div class="text-center mb-0 w-full text-gray-600 dark:text-neutral-400" v-html="errorMessage"/>
				<div v-if="stackTrace && showStackTrace" v-html="stackTrace" class="overflow-x-auto text-gray-600 dark:text-neutral-400"/>

				<wrapper v-if="statusCode > 399 && statusCode !== 503" class="mt-8" grow center>
					<btn @click="handleErrorReset" :icon="icons.back" shadow large color="primary" :label="t('action.back')"/>
					<btn v-if="stackTrace" v-model="showStackTrace" shadow large label="DEBUG" @click="showStackTrace = !showStackTrace"/>
				</wrapper>
			</div>
		</container>
	</NuxtLayout>
</template>

<script setup lang="ts">
import {isString} from "@spoferan/spoferan-ts-core";
import {mdiChevronLeft, mdiExclamation} from "@mdi/js";
import {backIcon} from "@spoferan/nuxt-spoferan/icons";

const props = defineProps({
	error: Object
})

const router = useRouter();
const config = useRuntimeConfig();
const {t} = useI18n();
const isDebug = config.public.debug === true

const statusCode = (props.error && props.error.statusCode) || 500;
const errorTitle = t(`error.${statusCode}.title`);
const stackTrace = isDebug ? props.error.stack : null;
const showStackTrace = ref(false);

const showServerMessageResponseCodes = [404, 403];

const errorMessage = computed(() => {
	if (((isDebug && showStackTrace.value) || showServerMessageResponseCodes.indexOf(statusCode) > 0) && props.error.message && isString(props.error.message) && props.error.message !== 'This page could not be found') {
		return props.error.message;
	}

	return t(`error.${statusCode}.message`)
});

const icons = {
	warning: mdiExclamation,
	back: backIcon,
}

const handleErrorReset = () => {
	clearError();
	router.go(-1);
}

// This will be reactive even you change title/description above
useHead({
	htmlAttrs: {
		lang: 'de'
	},

	title: 'SPOFERAN - Mein Sportnetzwerk',

	meta: [
		{charset: 'utf-8'},
		{name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
		{name: 'author', content: 'SPOFERAN GmbH'},
		{name: 'theme-color', content: '#008200'},

		// Fix for IE 11 rendering issues
		{
			'http-equiv': 'X-UA-Compatible',
			'content': 'IE=edge'
		},

		// Activate iOS PWA Splash Screen
		{name: 'apple-mobile-web-app-capable', content: 'yes'}
	],
	link: [
		{rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
		{rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
		{rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
		{rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
		{rel: 'mask-icon', type: 'image/png', color: '#008200', href: '/images/favicons/safari-pinned-tab.svg'}
	]
})
</script>